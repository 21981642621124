import React from "react";
import QtyButton from "../product-list/QtyButton";

export const toCurrency = (n) => {
  const curr = "RUB";
  const LanguageFormat = "Ru-ru";
  return Intl.NumberFormat(LanguageFormat, {
    style: "currency",
    currency: curr,
    minimumFractionDigits: 0,
  }).format(n);
};
// export const getStockDisplay = (stock, type) => {
//   switch (stock) {
//     case undefined:
//       break;
//     case null:
//       return (
//         <div className="pt-10 text-sm text-gray-500">Загрузка данных...</div>
//       );
//     case -1:
//       return "Ошибка загрузки данных";
//     default:
//       if (stock[type].qty === 0) {
//         return (
//           <div className="pt-10 text-sm text-yellow-600">Нет в наличии</div>
//         );
//       } else {
//         return <div className="pt-10 text-sm text-green-500">В наличии</div>;
//       }
//   }
// };

export default function ProductInfo({ name, types }) {
  // const stockDisplay = getStockDisplay(stock, selectedVariant);
  return (
    <div className="md:px-10">
      <div className="flex-col space-y-5 md:py-0 py-10">
        {types.code ? (
          <div className="flex text-gray-400 text-sm">
            Артикул: {types.code}
          </div>
        ) : null}
        <div className="md:pb-5 font-semibold font-header md:text-xl text-lg md:border-b">
          {name}
        </div>
        <div className="flex flex-col md:space-y-3 text-gray-600">
          <div>
            <span className="border-b border-dashed">Марка стали: </span>
            {types.steel}
          </div>
          <div>
            <span className="border-b border-dashed">Производитель: </span>
            {types.brand.brand}
          </div>
          {!!types.len ? (
            <div>
              <span className="border-b border-dashed">Длина клинка: </span>
              {types.len} мм.
            </div>
          ) : null}
        </div>
        {types.price !== 0 ? (
          <>
            <div className="flex md:flex-col flex-row space-x-10 md:space-x-0 md:space-y-5 px-4 md:px-0 md:bg-opacity-0 bg-redmachete bg-opacity-5">
              <div className="text-black md:py-10 py-4 font-semibold font-header text-2xl md:border-b">
                {toCurrency(types.price)}
              </div>
              <div className="flex space-x-4">
                <QtyButton selectedVariant={types} types={types} name={name} />
              </div>
            </div>
            <div className="flex-col text-gray-500 py-5 space-y-5">
              <div className="flex space-x-5 items-center">
                <div className="w-14">
                  <img
                    src="https://cdn4.iconfinder.com/data/icons/logistics-and-shipping-5/85/fast_delivery_truck_logistics_shipping-512.png"
                    alt="Доставка"
                  />
                </div>
                <div className="flex-col">
                  <div>Доставка</div>
                  <div>
                    Завтра -{" "}
                    <span className="text-green-500 font-semibold font-header">
                      бесплатно
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          null
        )}
      </div>
    </div>
  );
}
