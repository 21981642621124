import React, { useState } from "react";
import { Link } from "gatsby";
import Layout from "../pages/layout";
import ProductImages from "../components/product-detail/ProductImages";
import ProductInfo from "../components/product-detail/ProductInfo";

export default function ProductDetail({
  pageContext: { name, cat, types, id },
}) {
  const [selectedVariant, setSelectedVariant] = useState(0);
  const [selectedImage, setSelectedImage] = useState(0);

  return (
    <Layout>
      <div className="md:max-w-7xl mx-auto md:py-16 py-4 md:px-0 px-4">
        <div className="flex-col">
          <div className="text-gray-500 text-sm md:pb-10">
            Каталог /{" "}
            <Link
              to={`/catalog/${cat.slug}`}
              className="text-gray-700 underline"
            >
              {cat.title}
            </Link>{" "}
            / {name}
          </div>
          <div className="flex flex-col md:flex-row md:space-x-5 mx-auto">
            <div className="md:w-1/3 flex-col">
              <ProductImages
                images={types.images}
                name={name}
                selectedImage={selectedImage}
                setSelectedImage={setSelectedImage}
              />
            </div>
            <div className="md:w-2/3">
              <ProductInfo
                name={name}
                types={types}
                good={id}
                selectedVariant={selectedVariant}
                setSelectedVariant={setSelectedVariant}
              />
            </div>
          </div>
          <div className="flex-col border-t mt-10 py-5">
            <div className="text-3xl font-header font-semibold">
              Характеристики
            </div>
            <div className="flex w-1/2 py-10 text-gray-400">
              <div className="flex flex-col md:space-y-3 text-gray-600">
                <div className="space-x-5 hover:bg-gray-100 p-2">
                  <span className="border-b border-dashed">
                    Общая длина (мм):
                  </span>
                  <span className="font-semibold">{types.fullSize}</span>
                </div>
                <div className="space-x-5 hover:bg-gray-100 p-2">
                  <span className="border-b border-dashed">
                    Длина клинка (мм):
                  </span>
                  <span className="font-semibold">{types.len}</span>
                </div>
                <div className="space-x-5 hover:bg-gray-100 p-2">
                  <span className="border-b border-dashed">
                    Толщина обуха (мм):
                  </span>
                  <span className="font-semibold">{types.width}</span>
                </div>
                <div className="space-x-5 hover:bg-gray-100 p-2">
                  <span className="border-b border-dashed">Марка стали:</span>
                  <span className="font-semibold">{types.steel}</span>
                </div>
                <div className="space-x-5 hover:bg-gray-100 p-2">
                  <span className="border-b border-dashed">
                    Тип обработки клинка:
                  </span>
                  <span className="font-semibold">{types.steelType}</span>
                </div>
                <div className="space-x-5 hover:bg-gray-100 p-2">
                  <span className="border-b border-dashed">
                    Длина рукояти (мм):
                  </span>
                  <span className="font-semibold">{types.handleLen}</span>
                </div>
                <div className="space-x-5 hover:bg-gray-100 p-2">
                  <span className="border-b border-dashed">
                    Материал рукояти:
                  </span>
                  <span className="font-semibold">{types.handleType}</span>
                </div>
                <div className="space-x-5 hover:bg-gray-100 p-2">
                  <span className="border-b border-dashed">Тип замка:</span>
                  <span className="font-semibold">{types.lockType}</span>
                </div>
                <div className="space-x-5 hover:bg-gray-100 p-2">
                  <span className="border-b border-dashed">Вес (гр):</span>
                  <span className="font-semibold">{types.weight}</span>
                </div>
                <div className="space-x-5 hover:bg-gray-100 p-2">
                  <span className="border-b border-dashed">
                    Материал клипсы:
                  </span>
                  <span className="font-semibold">{types.clipType}</span>
                </div>
                <div className="space-x-5 hover:bg-gray-100 p-2">
                  <span className="border-b border-dashed">
                    Страна изготовитель:
                  </span>
                  <span className="font-semibold">{types.country}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
