import React from "react";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
export default function ProductImages({
  images,
  name,
  selectedImage,
  setSelectedImage,
}) {
  const img = images[0]
    ? images[selectedImage].localFile.childImageSharp.gatsbyImageData
    : null;
  return (
    <>
      <div className="flex justify-center">
        {images[0] ? (
          <GatsbyImage image={img} alt={name} title={name} />
        ) : (
          <StaticImage src="../../images/noimg.jpg" alt={name} title={name} />
        )}
      </div>
      {images[0] ? (
        <div className="grid grid-cols-4 gap-4">
          {images.map((image, i) => {
            return (
              <div key={i} className="border">
                <button type="button" onClick={() => setSelectedImage(i)}>
                  <img
                    src={image.formats.thumbnail.url}
                    alt={name}
                    title={name}
                    className="w-24"
                  />
                </button>
              </div>
            );
          })}
        </div>
      ) : null}
    </>
  );
}
